import Web3
 from "web3";
const setAddress=(address)=> async (dispatch)=>{
  dispatch({ type: "ADD_ADDRESS" , address: address?Web3.utils.toChecksumAddress(address): ''});
}
const setWallet=(wallet)=> async (dispatch)=>{
  dispatch({ type: "ADD_WALLET" ,wallet});
}
const setWeb3Provider=(web3Provider)=> async (dispatch)=>{
  dispatch({ type: "ADD_WEB3_PROVIDER" ,web3Provider});
}
const setJsonRpcSigner=(jsonRpcSigner)=> async (dispatch)=>{
  dispatch({ type: "ADD_JSON_RPC_SIGNER" ,jsonRpcSigner});
}
const setJsonRpcProvider=(jsonRpcProvider)=> async (dispatch)=>{
  dispatch({ type: "ADD_JSON_RPC_PROVIDER" ,jsonRpcProvider});
}
const setNetworkId=(network_id)=> async (dispatch)=>{
  dispatch({ type: "ADD_NETWORK_ID" ,network_id});
}
const setNetworkName=(network_name)=> async (dispatch)=>{
  dispatch({ type: "ADD_NETWORK_NAME" ,network_name});
}
const resetWallet=()=> async (dispatch)=>{
  dispatch({ type: "RESET_WALLET"});
}
const setChainType=(chainType)=> async (dispatch)=>{
  dispatch({ type: "ADD_CHAIN_TYPE",chainType});
}


export { 
  setAddress ,resetWallet , setWallet, setJsonRpcSigner, setJsonRpcProvider,setNetworkName, setNetworkId,setWeb3Provider,setChainType
};