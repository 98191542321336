import React from 'react'
import { connect } from 'react-redux'
import {HashRouter,Route,Routes} from "react-router-dom";

import Claim from '../pages/claim.js';
function AppRouter(){
    
    return(
        <>
            <HashRouter>
                <Routes>
                    <Route exact path='/' element={<Claim/>}></Route>
                </Routes>
            </HashRouter>
        </>
    )
    
}

const mapStateToProps = (state) => ({

})
  
const mapDispatchToProps = (dispatch) => ({
})
  
  
export default connect(mapStateToProps,mapDispatchToProps)(AppRouter);