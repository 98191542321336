
import React from 'react';
import Header from './Header'
import { useSelector} from 'react-redux';
import darkClass from '../../util/theme'
import { useEffect } from 'react';


const DashboardLayout= ({ children }) => {
    const {theme} = useSelector(state => state.themeReducer);
    useEffect(()=>{
        if(theme==='dark'){
            document.getElementById('body').classList.add('body-dark')
            document.getElementById('body').classList.remove('body')
        }else{
            document.getElementById('body').classList.add('body')
            document.getElementById('body').classList.remove('body-dark')
        }
    },[theme])

    return (
         <div>
            {
                theme==='dark'
                ?(
                        <link rel="stylesheet" href="/css/dark.min.css"/>
                ):(
                    <>
                        {/* <link rel="stylesheet" href="/css/light.css"/> */}
                    </>
                )
            }
            <div className="page-wrapper">
                <div className="page-box">
                    <Header/> 
                    <div className={darkClass('page-section',theme)}>
                        {children}
                    </div>
                </div>
            </div>
         </div>
    );
  };
  
  export default DashboardLayout;