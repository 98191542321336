
  
  function themeReducer(state={},action){
    switch (action.type) {
      case "ADD_THEME":
        return { 
          data: action.payload,
          theme:action.theme
        };
      case "ADD_THEME_FAIL":
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function sidebarReducer(state={},action){
    switch (action.type) {
      case "ADD_SIDEBAR":
        return { 
          sidebar: action.sidebar,
        };
      default: return state;
    }
  }

  function collateralReducer(state={},action){
    switch (action.type) {
      case "ADD_COLLATERAL_TYPE":
        return { 
          collateral: action.collateral,
        };
      default: return state;
    }
  }

  export {
    themeReducer,sidebarReducer,collateralReducer
  }