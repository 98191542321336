import React from 'react';
import { useDispatch} from 'react-redux';
import { setSidebar } from '../../store/actions/settingsActions';
import { useState,useEffect } from 'react';
import { useSelector} from 'react-redux';
import darkClass from '../../util/theme'
import ConnectBtn from './header/ConnectBtn' 

export default function Header() {
    const dispatch = useDispatch();
    const [open,setOpen] = useState(false)
    const {theme} = useSelector(state => state.themeReducer);
    const sidebarState = useSelector(state => state.sidebarReducer);
    const {chainType} = useSelector(state => state.chainTypeReducer);


    function toggleSidebar(){
        dispatch(setSidebar(!open))
        setOpen(!open)
    }

    function getImgUrl(){
        if(theme==='dark'){
            return "/images/icon/x-logo-dark.png"
        }else{
            return "/images/icon/x-logo.jpg"
        }
    }

    useEffect(()=>{
        setOpen(sidebarState.sidebar)
    },[sidebarState])
    useEffect(()=>{
        console.log('theChainType---->', chainType);
    },[chainType])
    return (
        <div className={darkClass('header',theme)}>
            <div className="header-icon">
                <svg viewBox="0 0 20 20" onClick={()=>toggleSidebar()} fill="#545454" width="26" height="26" className="menu-burer mr-3"><path  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" ></path></svg>

                {/* <img src="/images/icon/menu.svg" className=" menu-burer" /> */}
                <a href="https://bankx.io" target="_blank" rel="noreferrer noopener">
                    <img src={getImgUrl()} alt="logo" />
                </a>
            </div>
            <div className="header-button-wrapper">
                {/* <button onClick={()=>switchChain('eth')} className={`button-tab1 ${chainType==='eth'?'button-tab1-active':''}`}>ETH</button>
                <button onClick={()=>switchChain('bsc')} className={`${darkClass("button-tab1",theme)} ${chainType==='bsc'?'button-tab1-active':''}`}>BSC</button> */}
                <ConnectBtn />
            </div>
        </div>
    )
  }
  