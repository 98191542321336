import bankX from "./bankX";
import xsd from "./xsd";
// import xsd3 from "./xsd3";

export default [
    bankX,
    xsd,
    // xsd3
]


 