import { createStore, combineReducers, applyMiddleware,compose } from 'redux';
import thunk from 'redux-thunk';


import {
  themeReducer,
  sidebarReducer,
  collateralReducer
} from './reducers/settingsReducers';

  // web3Reducer,accountReducer,setContract
  import {web3Reducer,chainTypeReducer} from './reducers/web3Reducers'
const initialState = {
};

const reducer = combineReducers({
  themeReducer:themeReducer,
  sidebarReducer:sidebarReducer,
  collateralReducer:collateralReducer,
  web3Reducer,
  chainTypeReducer
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;

