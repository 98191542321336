import http from "../../util/http"

const setTheme=(theme)=> async (dispatch)=>{
  dispatch({ type: "ADD_THEME" ,theme:theme});
}
const createTheme = (farm_id,actor_name,params) => async (dispatch) => {
  try {
    const { data } = await http.patch(`/api/v1/farms/settings/${farm_id}/actors/${actor_name}`,params);
    
    dispatch({ type: "ADD_THEME", payload: data });
    
  } catch (error) {
    dispatch({ type: "ADD_THEME_FAIL", payload: error.message });
  }
}

const setSidebar = (sidebar) => async (dispatch) => {
  dispatch({ type: "ADD_SIDEBAR" ,sidebar:sidebar});
}
const setCollateralType = (collateral) => async (dispatch) => {
  dispatch({ type: "ADD_COLLATERAL_TYPE" ,collateral:collateral});
}

export { 
    createTheme ,setTheme,setSidebar,setCollateralType
};