import { toHex } from './numbers';
import { mapValues } from 'lodash';

// https://chainlist.org/
export const ChainIdsInt = {
    BinanceSmartChain: 56,
    BinanceTestnet: 97,
    EthereumMainnet: 1,
    Kovan: 42,
    Rinkeby: 4,
    Goerli: 5,
    Sepolia: 11155111
}

export const ChainIdsHex = mapValues(ChainIdsInt, toHex);

export const Chains = {
    [ChainIdsInt.Rinkeby]: {
        id: ChainIdsHex.Rinkeby,
        // BSC testnet.... shouldn't this be used in network 97 instead???
        // reference: https://github.com/Lance-Parker/Claim-App/blob/dfd34e37256ef7daf1e6c121cae7ed7de785c415/src/components/layouts/header/ConnectBtn.js#L18
        // rpcUrl: 'https://speedy-nodes-nyc.moralis.io/de5ffdecc0da895478c3c5e5/rinkeby/testnet',
        rpcUrl: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        // rpcUrl: 'https://rpc.ankr.com/eth_rinkeby',
        token: 'ETH',
        label: 'Rinkeby Testnet',
        // wethAddress: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    },
    [ChainIdsInt.Goerli]: {
        id: ChainIdsHex.Goerli,
        // BSC testnet.... shouldn't this be used in network 97 instead???
        // reference: https://github.com/Lance-Parker/Claim-App/blob/dfd34e37256ef7daf1e6c121cae7ed7de785c415/src/components/layouts/header/ConnectBtn.js#L18
        // rpcUrl: 'https://speedy-nodes-nyc.moralis.io/de5ffdecc0da895478c3c5e5/rinkeby/testnet',
        rpcUrl: 'https://goerli.infura.io/v3/0585168c69d04feeb74f94a49d9f2356',
        // rpcUrl: 'https://rpc.ankr.com/eth_rinkeby',
        token: 'ETH',
        label: 'Goerli Testnet',
        // wethAddress: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    },
    [ChainIdsInt.Sepolia]: {
        id: ChainIdsHex.Sepolia,
        // BSC testnet.... shouldn't this be used in network 97 instead???
        // reference: https://github.com/Lance-Parker/Claim-App/blob/dfd34e37256ef7daf1e6c121cae7ed7de785c415/src/components/layouts/header/ConnectBtn.js#L18
        // rpcUrl: 'https://speedy-nodes-nyc.moralis.io/de5ffdecc0da895478c3c5e5/rinkeby/testnet',
        rpcUrl: 'https://sepolia.infura.io/v3/0585168c69d04feeb74f94a49d9f2356',
        // rpcUrl: 'https://rpc.ankr.com/eth_rinkeby',
        token: 'ETH',
        label: 'Sepolia Testnet',
        // wethAddress: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    },
    [ChainIdsInt.BinanceSmartChain]: {
        id: ChainIdsHex.BinanceSmartChain,
        token: 'BNB',
        label: 'BSC Mainnet',
        rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    },
    [ChainIdsInt.EthereumMainnet]: {
        id: ChainIdsHex.EthereumMainnet,
        token: 'ETH',
        label: 'Ethereum Mainnet',
        rpcUrl: 'https://mainnet.infura.io/v3/050904dc37ca4946ab6c6a3901990453',
    },
    [ChainIdsInt.Kovan]: {
        id: ChainIdsHex.Kovan,
        token: 'KETH',
        label: 'Kovan Test Network',
        rpcUrl: 'https://kovan.infura.io/v3/050904dc37ca4946ab6c6a3901990453',
    },
    [ChainIdsInt.BinanceTestnet]: {
        id: ChainIdsHex.BinanceTestnet,
        token: 'BNB',
        label: 'BSC Testnet',
        rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    }
};

export function getChainMeta(id) {
    return Chains[id];
}
