import React from 'react';
import DefaultLayout from "../components/layouts/DefaultLayout"
import { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { useSelector} from 'react-redux';
import darkClass from '../util/theme'
import {AirdropABI} from '../web3/abis/airdrop';
import airdrops from '../data/airdrops';
import Web3 from 'web3';
import ConnectBtn from '../components/layouts/header/ConnectBtn';

const compareCanClaim =  function(obj1, obj2) {
    const a = !!obj1.canClaim;
    const b = !!obj2.canClaim;
    if (Number(a) > Number(b)) {
      return -1;
    }
    if (Number(a) < Number(b)) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

const APP_CONTRACTS = {
    "APP_AIRDROP_CONTRACT_ETH": "0x13E517bBf7A5eAE43692709F3b20659df88B3558"
};

function Home(props) {
    const [loading,setLoading]=useState(false)
    const {theme} = useSelector(state => state.themeReducer);
    const {web3Provider,address} = useSelector(state => state.web3Reducer);
    const {chainType} = useSelector(state => state.chainTypeReducer);
    const [data, setData] = useState([]);
    const [claiming, setClaiming] = useState({});
    const [loadingData, setLoadingData] = useState(false);

    useEffect(()=> {
        if (web3Provider) {
            web3Provider.eth.getAccounts().then();
        }
    }, [web3Provider]);

    function loadAirdrops(address, chainType) {
        address = Web3.utils.toChecksumAddress(address);
        const contract = new web3Provider.eth.Contract(
            AirdropABI,
            APP_CONTRACTS[`APP_AIRDROP_CONTRACT_${chainType.toUpperCase()}`],
        );
        console.log("=== contract: ", contract);
        const drops = airdrops.filter(a=>a[chainType].deadline > (Date.now()/1000)).map((airdrop)=> {
            airdrop =  airdrop[chainType];
            airdrop.canClaim = false;
            airdrop.amount = "0";
            airdrop.proof = [];
            if (airdrop.receivers) {
                airdrop.amount = airdrop.receivers[address] ? airdrop.receivers[address].amount : "0";
                airdrop.proof = airdrop.receivers[address] ? airdrop.receivers[address].proof : [];
            }
            console.log("=== airdrop.amount", airdrop.amount);
            if (airdrop.amount !== "0") {
                setLoadingData(true);
                return contract.methods.canClaim(address, airdrop.name, airdrop.token, airdrop.amount, airdrop.merkleRoot, airdrop.proof).call()
                .then(result=>{
                    airdrop.canClaim = true;
                    return airdrop
                })
                .catch(error=>{
                    if(error.message.includes('Already claimed')) {
                        airdrop.claimed = true;
                    }
                    airdrop.canClaim = false;
                    return airdrop;
                });
            }
            return airdrop;
        });
        Promise.all(drops).then(result=>{
            setLoadingData(false);
            setData([...result]);
        });
    }

    console.log("==== chainType: ", chainType)
    console.log("==== address", address)


    useEffect(()=> {
        if (chainType && address && web3Provider) {
            console.log("==== loadAirdrops")
            loadAirdrops(address, chainType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, chainType, web3Provider]);

    async function handleClaim(airdrop){
        const contract = new web3Provider.eth.Contract(
            AirdropABI,
            APP_CONTRACTS[`APP_AIRDROP_CONTRACT_${chainType.toUpperCase()}`],
        );
        
        setLoading(true);
        setClaiming({ [airdrop.name]: true });
        contract.methods.claim(airdrop.name, airdrop.token, airdrop.amount, airdrop.merkleRoot, airdrop.proof).send({ from:address }).then( (res) => {
          setLoading(false);
          setClaiming({});
          loadAirdrops(address, chainType);
        }).catch((error) => {
            setLoading(false);
            setClaiming({});
          })
    }
    return (
      <DefaultLayout>
        <div className={darkClass("content-wrapper",theme)} style={{width:"100%"}}>
            <div className={`${darkClass("main-card-header",theme)} center-out`} style={{marginBottom:"0px",marginTop:"20px"}}>
                <h3>Claim Your BankX And XSD Tokens</h3>
                <h4>You must do a separate transaction</h4>
                <h4>for each token on each chain.</h4>
            </div>
            <div className={darkClass("main-card",theme)}>
                <div className={darkClass("main-card-inner",theme)}>
                    <div className="main-card-header">
                        {/* <h3>Claim</h3> */}
                        <div className="setting-wrapper">
                             </div>
                    </div>
                    <div className="main-card-body">
                       
                            {address && !loadingData && <div className={`${darkClass("side-info-box2",theme)} box-margin`}>
                                {
                                data.sort(compareCanClaim).map((airdrop) => (
                                    <div key={airdrop.name}><div style={{display: "flex"}}>
                                    <div className='col-8' style={{textAlign: 'left', lineHeight: 1}}>
                                            <label>{airdrop.name} to claim</label>
                                            <br/>
                                            <h5>{Web3.utils.fromWei(airdrop.amount)}</h5>
                                        </div>
                                        <div  style={{textAlign: 'right'}} className='col-4'>
                                        
                                       {
                                       (Date.now()/1000) < airdrop.deadline && !airdrop.claimed &&
                                            <div>
                                               <button disabled={loading || airdrop.amount === "0" || claiming[airdrop.name] || !airdrop.canClaim} onClick={()=>handleClaim(airdrop)} className={`button-tab ${loading || airdrop.amount === "0" || !airdrop.canClaim?'':'button-tab-active'}`}>
                                                {!claiming[airdrop.name] ?'Claim': <img src="/images/preloader/white.svg" alt="" style={{width:"25px",height:"25px",marginRight:"5px"}}  />}
                                               </button>
                                            </div>
                                        }
                                        {airdrop.claimed && <h6>Claimed!</h6>}
                                        </div>
                                    </div>
                                    </div>))
                                }
                            </div>}
                            {loadingData && <div className="btn-wrap">
                            <img src="/images/preloader/black.svg" alt="" style={{width:"25px",height:"25px",marginRight:"5px"}}  />
                            </div>}
                            {!address && <div className="btn-wrap">
                            <ConnectBtn/>
                            </div>}
                     
                        
                    </div>
                </div>
                
            </div>
        </div>
      </DefaultLayout>
    
  )
}
const mapStateToProps = (state) => ({
})
  
const mapDispatchToProps = (dispatch) => ({
})
  
  
export default connect(mapStateToProps,mapDispatchToProps)(Home);
