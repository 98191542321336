
import React from 'react';
import AppRouter from './config/router.js'

function App() {
  return (
    <AppRouter/>
  );
}

export default App;
