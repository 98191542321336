
export function toHex(value) {
    if (Number.isNaN(Number(value))) {
        return value;
    }

    const hexValue = value.toString(16);

    return `0x${hexValue}`;
}
