
  export function web3Reducer(state={},action){
    console.log('=== action.type: ', action.type)
    switch (action.type) {
      case "ADD_WEB3_PROVIDER":
        return { 
          ...state,
          web3Provider: action.web3Provider,
        };
      case "ADD_JSON_RPC_SIGNER":
        return { 
          ...state,
          jsonRpcSigner: action.jsonRpcSigner,
        };
      case "ADD_WALLET":
        return { 
          ...state,
          wallet: action.wallet,
        };
      case "ADD_ADDRESS":
        return { 
          ...state,
          address: action.address,
        };
      case "ADD_JSON_RPC_PROVIDER":
        return { 
          ...state,
          jsonRpcProvider: action.jsonRpcProvider,
        };
      case "ADD_NETWORK_ID":
        return { 
          ...state,
          network_id: action.network_id,
        };
      case "ADD_NETWORK_NAME":
        return { 
          ...state,
          network_name: action.network_name,
        };
      case "RESET_WALLET":
        return { 
          ...state,
          wallet: null,
          web3Provider: null,
        };
      default: return state;
    }
  }
  export function chainTypeReducer(state={},action){
    switch(action.type){
      case "ADD_CHAIN_TYPE":
        return { 
          chainType: action.chainType,
        };
      default: return state;
    }
  }