
import axios from "axios";
// import { getToken } from "./get-token";

const http = axios.create({
  baseURL: 'http://3.12.39.70', // TODO: take this api URL from env
  timeout: 30000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
// process.env.NEXT_PUBLIC_REST_API_ENDPOINT

// Change request data/error here
// http.interceptors.request.use(
//   (config) => {
//     const token = getToken();
//     config.headers = {
//       ...config.headers,
//       Authorization: `Bearer ${token ? token : ""}`,
//     };
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default http;
