import React, { useCallback } from 'react';
import { useSelector} from 'react-redux';
import { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import darkClass from '../../../util/theme'
import Web3 from "web3";
import {resetWallet, setAddress, setChainType, setWeb3Provider} from '../../../store/actions/web3Actions'
import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import { ethers } from 'ethers';
import { ChainIdsInt, getChainMeta } from '../../../helpers/chains';

export default function ConnectBtn() {

    const dispatch = useDispatch();
    const {theme} = useSelector(state => state.themeReducer);
    const {web3Provider, address} = useSelector(state => state.web3Reducer);

    const disconnectWallet = async (dispatch) => {
      dispatch(resetWallet());
      dispatch(setAddress(null));
    };

    const switchChain = useCallback((chainId) => {
      switch(parseInt(chainId, 16)) {
        case 1:
        case 3:
        case 4:
        case 5:
        case 11155111:
          dispatch(setChainType('eth'));
          break;
        case 56:
        case 97:
          dispatch(setChainType('bsc'));
          break;
        default:
          break;
      }
    }, [dispatch])

    useEffect(()=> {
        if (web3Provider) {
          web3Provider.currentProvider.on('chainChanged',(chainId) => {
            switchChain(chainId);
          });
          web3Provider.currentProvider.on('accountsChanged',(accounts) => {
            dispatch(setAddress(accounts[0]));
          });

            web3Provider.eth.getAccounts().then(accounts=>{
              dispatch(setAddress(accounts[0]));
            });
            switchChain(web3Provider.currentProvider.chainId);
            console.log('=== switchChain', web3Provider)

            if (![process.env.REACT_APP_ETH_CHAIN_ID, process.env.REACT_APP_BSC_CHAIN_ID].includes(String(parseInt(web3Provider.currentProvider.chainId, 16)))) {
              web3Provider.currentProvider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: `0x${Number(process.env.REACT_APP_ETH_CHAIN_ID).toString(16)}` }], // chainId must be in hexadecimal numbers
                      }).then((d)=> {
                      });
            }
        } else setAddress(null);
    }, [web3Provider, dispatch, switchChain]);

    useEffect(()=>{
    },[theme])
    
    // TODO: Here is where we connecting a wallet
    async function readyToTransact() {
      console.log("===Show modal to connect")
      
      const injected = injectedModule()
      const walletConnect = walletConnectModule();

      const onboard = Onboard({
        wallets: [injected, walletConnect],
        chains: [getChainMeta(ChainIdsInt.Sepolia)],
        appMetadata: {
          name: 'BankX',
          icon: '/images/icon/x-logo.jpg',
          description: 'Please select a wallet to connect.',
          recommendedInjectedWallets: [{
              name: 'MetaMask',
              url: 'https://metamask.io'
          }]
        },
      })

      const wallets = await onboard.connectWallet()
      console.log("===wallets", wallets);
      if (wallets[0]) {
        const provider_full = new ethers.providers.Web3Provider(
          wallets[0].provider,
          'any'
        )
        const provider = provider_full.provider
        console.log('=== provider: ',provider)
        const web3 = new Web3(provider);
        console.log('=== web3: ', web3);
        dispatch(setWeb3Provider(web3));
      }
      // NEW =========
      // Handle changing of an acccount from plugin
      const general_state = onboard.state.select('wallets')
      general_state.subscribe(update => {
        if (update[0]) {
          console.log('state update for wallets: ', update)
          if (update[0].accounts[0].address) {
            dispatch(setAddress(update[0].accounts[0].address));
          }
        } else {
          console.log('state update disconnect: ', update)
          disconnectWallet(dispatch)
        }
      }
      )

      console.log(web3Provider,'web3Provider')
    }


    return (
        <>
        {
          (!address)
          ?
          (<button 
              onClick={async () => {
                await readyToTransact();
              }} 
              className={darkClass("header-button",theme)}
            >
            Connect Wallet
          </button>)
          :
          (
            <div></div>
          )
        } 
        </>        
    )
  }
  